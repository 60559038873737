.aboutImage {
    height: 26vh;
    width: auto;
    border-radius: 1rem;
    max-width: 100%;
    /* height: auto; */
}

.firstLetter {
    font-family: TitleFont;
    font-weight: bold;
    text-shadow: 1px 1.5px rgba(0, 0, 0, 0.2);
    margin-right: 1px;
    font-size: 2rem !important;
}

#aboutOutterContainer1 {
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(http://www.desktopimages.org/pictures/2014/0317/1/purple-gaussian-blur-wallpaper-60511.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.aboutInnerContainer {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 3rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#aboutOutterContainer2 {
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(https://media.istockphoto.com/photos/blurred-blue-sky-background-picture-id481005410?k=6&m=481005410&s=612x612&w=0&h=JpVbec0ZhYfxcEGbx6FaCDYaykjdExnPtHNGkDr2CLY=);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#aboutOutterContainer3 {
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(https://il9.picdn.net/shutterstock/videos/5495999/thumb/1.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#aboutOutterContainer4 {
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(https://images.all-free-download.com/images/graphiclarge/yellow_blurred_background_200165.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.apertureIcon {
    height: 4rem !important;
    width: auto
}

.aboutUl {
    list-style: none; /* Remove HTML bullets */
    padding: 0;
    margin: 0;
}
  
.aboutLi { 
    padding-left: 16px; 
}

.aboutLi::before {
    content: url(./apertureBullet.png); /* Insert content that looks like bullets */
    padding-right: 8px;
}

#blogPostContainer {
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(http://blog.hdwallsource.com/wp-content/uploads/2016/01/nature-bench-blur-wallpaper-44620-45750-hd-wallpapers.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  #innerblogPostContainer {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  }


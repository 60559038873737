
@-webkit-keyframes fadein {
    from {opacity: 0.5;}
    to {opacity: 1;}
}

@keyframes fadein {
    from {opacity: 0.5;}
    to {opacity: 1;}
}

.fade-in {
  -webkit-animation: fadein 1s ease forwards;
          animation: fadein 1s ease forwards;
}
.aboutImage {
    height: 26vh;
    width: auto;
    border-radius: 1rem;
    max-width: 100%;
    /* height: auto; */
}

.firstLetter {
    font-family: TitleFont;
    font-weight: bold;
    text-shadow: 1px 1.5px rgba(0, 0, 0, 0.2);
    margin-right: 1px;
    font-size: 2rem !important;
}

#aboutOutterContainer1 {
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(http://www.desktopimages.org/pictures/2014/0317/1/purple-gaussian-blur-wallpaper-60511.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.aboutInnerContainer {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 3rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#aboutOutterContainer2 {
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(https://media.istockphoto.com/photos/blurred-blue-sky-background-picture-id481005410?k=6&m=481005410&s=612x612&w=0&h=JpVbec0ZhYfxcEGbx6FaCDYaykjdExnPtHNGkDr2CLY=);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#aboutOutterContainer3 {
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(https://il9.picdn.net/shutterstock/videos/5495999/thumb/1.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#aboutOutterContainer4 {
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(https://images.all-free-download.com/images/graphiclarge/yellow_blurred_background_200165.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.apertureIcon {
    height: 4rem !important;
    width: auto
}

.aboutUl {
    list-style: none; /* Remove HTML bullets */
    padding: 0;
    margin: 0;
}
  
.aboutLi { 
    padding-left: 16px; 
}

.aboutLi::before {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGLSURBVDhPdZNNSsNQFIUzcAFpV1AQwWG6gyyhS+gSMnLcLkADUrFOTKdCIe6gQx0IFSfOjDhxZlGKg5Lmeb7kPXmN9cCl6b3nnN6fNNiDWHGqeOx0Ol9hGFZ6LhQTxZHiX4SKSRzHpURmtVoZH2maml6vZ8SZ1uwWEN8mSVIhhNgG+dFotMVc3Ida5WEqsaWaPwaLxcKoMzMcDmsjPqWZN1LNLEHpt+wMlsulGQwGtZhnH3acQwyuZ7NZZfM1KNJRt9t9U/3V784hyzIMMgwKFuTDzpko2A04IYfIgY6V/whUKJkpiqLfNnW+daPbwbrNk/Ybgw1fSNK6SNt9BuQcDxPMpN1Se/EXyDgq8PKcURQYI1XOMhropHCeIFz4sznwTqj2rkUW4/G4dJdxYPGqX2Jw3C7au1f++XwOHWsMDHjta9xwKgp8Is7z3NIb+Aa2u/NG2uBAcc+c7MDfiQMG7gfEvVO4E+/gig23d4IQg36/vxGHufeKHSIF7T1zDZ3vU89sm78zNQ9B8ANjgO0pj0fdQgAAAABJRU5ErkJggg==); /* Insert content that looks like bullets */
    padding-right: 8px;
}

#blogPostContainer {
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(http://blog.hdwallsource.com/wp-content/uploads/2016/01/nature-bench-blur-wallpaper-44620-45750-hd-wallpapers.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  #innerblogPostContainer {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  }


#blogJumbotron{
  /* border-radius: 1rem; */
  background-image: url(/static/media/blogSunset.f43211c9.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.blogBtn {
  background: orangered !important;
  border-color: orangered !important;
  color: white;
}

.blogBtn:focus, .blogBtn.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 69, 0, 0.5) !important;
  color: white;
  text-decoration: none;
}

.blogBtn:hover, .blogBtn.hover {
  box-shadow: 0 0 0 0.2rem rgba(255, 69, 0, 0.5) !important;
  font-weight: bold;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  color: white;
  text-decoration: none;
}

.blogTitle{
  font-family: TitleFont;
  color: white;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.2);
  /* font-weight: bold; */
}

.blogSubTitle {
  font-family: TitleFont;
  color: black;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.2);
}

.cardShadow {
  /* text-shadow: 1px 1.5px rgba(0, 0, 0, 0.2); */
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.2), 0 5px 20px 0 rgba(0, 0, 0, 0.2);
}

.textWhiteHover {
  background-color: white;
  opacity: 0.9;
  margin: 20rem 2rem 2rem 2rem ;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* **border override removal for cards from bootstrap for styling ** */
.cardOverstyle {
  border-radius: 3rem !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.textWhiteHoverLatest {
  background-color: white;
  opacity: 0.9;
  margin: 2rem ;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.latestPostCard {
  border-radius: 3rem !important;
  background-color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#latestImg {
border-radius: 1rem;
}

/* Blog Post Main page */
#latestTime {
  color: white;
}

#latestImage {
  border-radius: 1rem;
  
}

/* used to create fluid images on blog post page */
div.blogPostImgGrp > img {
  border-radius: 1rem;
  max-width: 100%;
  height: auto;
}

/* used to round corners on main landing page */
div > img {
  border-radius: 1rem;
}

#blogPostContainer {
  color: white;
  font-weight: bolder;
  background-color: lightgray;
  border-radius: 3rem;
  background-image: url(http://blog.hdwallsource.com/wp-content/uploads/2016/01/nature-bench-blur-wallpaper-44620-45750-hd-wallpapers.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#innerblogPostContainer {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

@media (max-width: 575.98px) {
  /* Manage Display Headers for Mobile Devices */
.display-1 {
  font-size: 3rem !important;
  font-weight: 300;
  line-height: 1.0;
  }
}
  
    

.ContactBG {
    min-height: 100vh;
}

#contactContainer{
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(https://media.istockphoto.com/photos/blurred-blue-sky-background-picture-id481005410?k=6&m=481005410&s=612x612&w=0&h=JpVbec0ZhYfxcEGbx6FaCDYaykjdExnPtHNGkDr2CLY=);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#innerContactContainer {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.copyRImg {
    height: 9rem;
}

.cRListImg {
    height: 5rem;
}

.crWrapper {
    min-height: 100vh;
}

.cRUl {
    list-style: none; /* Remove HTML bullets */
    padding: 0;
    margin: 0;
}
  
.cRLi { 
    padding-left: 16px;
}

.cRLi::before {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAgMGMBzmvhm0AAAAyElEQVQoz4XRMU4CURQF0BMS6SS6BFqHZdjYqZ327sUG/IGwA3chrGIwgA0x7ADoxIzF/PkzxDHe27yX+/Pufe9TYSBYODhYCAZO0DX1rWjwaKJby/MTseKsejJtlQuFcel9jO3Kg76+Rx/JKCPEZukiZXpOM0a8x/IWNzY2XhsmObtYnmP7K8Wu4x90bGN1jadoUeOTlzhs7bIl5JAsrbl2p6fnvrHmFUz+PFSoTj1rld+c1b8xTkYlv4RaLpEZye3t5YalN/wAQGWVXAZRCtkAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDItMDNUMDY6NDg6MjgrMDA6MDAjwXFAAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAyLTAzVDA2OjQ4OjI4KzAwOjAwUpzJ/AAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=); /* Insert content that looks like bullets */
    padding-right: 8px;
}

#CRContainer {
    color: black;
    font-weight: bolder;
    border-radius: 3rem;
    background-image: url(https://www.free-largeimages.com/wp-content/uploads/2015/04/Blurry_Wallpaper_06.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .innerCRContainer {
    background-color: white;
    opacity: 0.9;
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  }
#img404 {
    height: 15vh;
    width: auto;
}

#errorTitle {
    font-family: TitleFont;
    text-shadow: 1px 2px rgba(0, 0, 0, 0.2);
}

.errorLink {
    color: orangered;
}

.errorLink.active {
    color: black;
    font-weight: bolder;
  }

  .errorLink:hover {
    color: black;
    font-weight: bolder;
    -webkit-text-decoration: black !important;
            text-decoration: black !important;
    -webkit-text-decoration-line: underline !important;
            text-decoration-line: underline !important;
    -webkit-text-decoration-style: dotted !important;
            text-decoration-style: dotted !important;
    -moz-text-decoration-line: underline !important;
  }
/** Generated by FG **/
/* @font-face {
	font-family: 'TitleFont';
	src: local('☺'), url('./assets/fonts/Feldn___.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

/* Apple like font altrnative */
/* @font-face {
	font-family: 'TitleFont';
	src: local('☺'), url('./assets/fonts/roundedFont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

/* National Park HEAVY Style Font */
/* @font-face {
	font-family: 'TitleFont';
	src: local('☺'), url('./assets/fonts/NP/NationalPark-Heavy.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

/* National Park REGULAR Style Font */
@font-face {
	font-family: 'TitleFont';
	src: local('\263A'), url(/static/media/NationalPark-Regular.eed8108a.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}

/* National Park OUTLINE Style Font */
/* @font-face {
	font-family: 'outlineFont';
	src: local('☺'), url('./assets/fonts/NP/NationalPark-Outline.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

/* National Park THIN Style Font */
@font-face {
	font-family: 'mainFont';
	src: local('\263A'), url(/static/media/NationalPark-Thin.8a67b6a8.woff) format('woff');
	font-weight: normal;
	font-style: normal;
}

body {
  font-family: mainFont !important;
}

.titleFont {
  font-family: TitleFont !important;
  /* font-weight: bold; */
  text-shadow: 1px 1.5px rgba(0, 0, 0, 0.2);
}

.navTitleFont {
  font-family: TitleFont;
  font-weight: bold;
  /* font-size: 1.75rem !important; */
  text-shadow: 1px 1.5px rgba(0, 0, 0, 0.2);
}

.txt-shadow {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}

.titleOr {
  color: orangered;
}

.nav-link {
  color: orangered;
}

.footer {
  background: lightgrey;
}

.footerLink.active {
  color: black;
  font-weight: bolder;
}

.titleBl {
  color: black;
}
/* 
.wrapper {
  min-height: 100vh;
} */

.navbar-toggler:focus {
    outline-color: orangered;
    outline-style: auto;
    outline-width: 5px;
    box-shadow: 0 0 20px 0 gray, 0 0 20px 0 gray;
}

.navbar-toggler:hover {
  outline-color: orangered;
  outline-style: auto;
  outline-width: 5px;
  box-shadow: 0 0 20px 0 gray, 0 0 20px 0 gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.nav-link:hover {
  color: black;
  font-weight: bolder;
  -webkit-text-decoration: black !important;
          text-decoration: black !important;
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
  -webkit-text-decoration-style: dotted !important;
          text-decoration-style: dotted !important;
  -moz-text-decoration-line: underline !important;
  -webkit-transition: 1s;
  transition: 1s;
}

.navIcon {
  max-height: 2rem;
  max-width: auto;
}

.navbar-toggler {
  border-color: rgba(0,0,0,.4) !important;
  padding: 0rem .2rem !important;
  box-shadow: 0 0 3px 0 gray, 0 0 3px 0 gray;
}

.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

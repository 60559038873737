#blogJumbotron{
  /* border-radius: 1rem; */
  background-image: url(./blogSunset.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.blogBtn {
  background: orangered !important;
  border-color: orangered !important;
  color: white;
}

.blogBtn:focus, .blogBtn.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 69, 0, 0.5) !important;
  color: white;
  text-decoration: none;
}

.blogBtn:hover, .blogBtn.hover {
  box-shadow: 0 0 0 0.2rem rgba(255, 69, 0, 0.5) !important;
  font-weight: bold;
  transition: 0.1s;
  color: white;
  text-decoration: none;
}

.blogTitle{
  font-family: TitleFont;
  color: white;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.2);
  /* font-weight: bold; */
}

.blogSubTitle {
  font-family: TitleFont;
  color: black;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.2);
}

.cardShadow {
  /* text-shadow: 1px 1.5px rgba(0, 0, 0, 0.2); */
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.2), 0 5px 20px 0 rgba(0, 0, 0, 0.2);
}

.textWhiteHover {
  background-color: white;
  opacity: 0.9;
  margin: 20rem 2rem 2rem 2rem ;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* **border override removal for cards from bootstrap for styling ** */
.cardOverstyle {
  border-radius: 3rem !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.textWhiteHoverLatest {
  background-color: white;
  opacity: 0.9;
  margin: 2rem ;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.latestPostCard {
  border-radius: 3rem !important;
  background-color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#latestImg {
border-radius: 1rem;
}

/* Blog Post Main page */
#latestTime {
  color: white;
}

#latestImage {
  border-radius: 1rem;
  
}

/* used to create fluid images on blog post page */
div.blogPostImgGrp > img {
  border-radius: 1rem;
  max-width: 100%;
  height: auto;
}

/* used to round corners on main landing page */
div > img {
  border-radius: 1rem;
}

#blogPostContainer {
  color: white;
  font-weight: bolder;
  background-color: lightgray;
  border-radius: 3rem;
  background-image: url(http://blog.hdwallsource.com/wp-content/uploads/2016/01/nature-bench-blur-wallpaper-44620-45750-hd-wallpapers.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#innerblogPostContainer {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

@media (max-width: 575.98px) {
  /* Manage Display Headers for Mobile Devices */
.display-1 {
  font-size: 3rem !important;
  font-weight: 300;
  line-height: 1.0;
  }
}
  
    
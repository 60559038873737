.ContactBG {
    min-height: 100vh;
}

#contactContainer{
    color: white;
    font-weight: bolder;
    background-color: lightgray;
    border-radius: 3rem;
    background-image: url(https://media.istockphoto.com/photos/blurred-blue-sky-background-picture-id481005410?k=6&m=481005410&s=612x612&w=0&h=JpVbec0ZhYfxcEGbx6FaCDYaykjdExnPtHNGkDr2CLY=);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#innerContactContainer {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
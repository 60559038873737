/** Generated by FG **/
/* @font-face {
	font-family: 'TitleFont';
	src: local('☺'), url('./assets/fonts/Feldn___.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

/* Apple like font altrnative */
/* @font-face {
	font-family: 'TitleFont';
	src: local('☺'), url('./assets/fonts/roundedFont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

/* National Park HEAVY Style Font */
/* @font-face {
	font-family: 'TitleFont';
	src: local('☺'), url('./assets/fonts/NP/NationalPark-Heavy.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

/* National Park REGULAR Style Font */
@font-face {
	font-family: 'TitleFont';
	src: local('☺'), url('./assets/fonts/NP/NationalPark-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

/* National Park OUTLINE Style Font */
/* @font-face {
	font-family: 'outlineFont';
	src: local('☺'), url('./assets/fonts/NP/NationalPark-Outline.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

/* National Park THIN Style Font */
@font-face {
	font-family: 'mainFont';
	src: local('☺'), url('./assets/fonts/NP/NationalPark-Thin.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

body {
  font-family: mainFont !important;
}

.titleFont {
  font-family: TitleFont !important;
  /* font-weight: bold; */
  text-shadow: 1px 1.5px rgba(0, 0, 0, 0.2);
}

.navTitleFont {
  font-family: TitleFont;
  font-weight: bold;
  /* font-size: 1.75rem !important; */
  text-shadow: 1px 1.5px rgba(0, 0, 0, 0.2);
}

.txt-shadow {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}

.titleOr {
  color: orangered;
}

.nav-link {
  color: orangered;
}

.footer {
  background: lightgrey;
}

.footerLink.active {
  color: black;
  font-weight: bolder;
}

.titleBl {
  color: black;
}
/* 
.wrapper {
  min-height: 100vh;
} */

.navbar-toggler:focus {
    outline-color: orangered;
    outline-style: auto;
    outline-width: 5px;
    box-shadow: 0 0 20px 0 gray, 0 0 20px 0 gray;
}

.navbar-toggler:hover {
  outline-color: orangered;
  outline-style: auto;
  outline-width: 5px;
  box-shadow: 0 0 20px 0 gray, 0 0 20px 0 gray;
  transition: 0.4s;
}

.nav-link:hover {
  color: black;
  font-weight: bolder;
  text-decoration: black !important;
  text-decoration-line: underline !important;
  text-decoration-style: dotted !important;
  -moz-text-decoration-line: underline !important;
  transition: 1s;
}

.navIcon {
  max-height: 2rem;
  max-width: auto;
}

.navbar-toggler {
  border-color: rgba(0,0,0,.4) !important;
  padding: 0rem .2rem !important;
  box-shadow: 0 0 3px 0 gray, 0 0 3px 0 gray;
}

.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}